<template>
  <div id="modalRegistrationRecommendCompany" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Passwort ändern</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 2">
            <font-awesome-icon icon="check" class="icon color-success" />
            <p>E-Mail versendet!<br>Wir haben Dir eine E-Mail zugesandt, mit der Du Dein Passwort ändern kannst. Prüfe bitte auch Deinen Spam-Ordner, falls Du sie nicht findest. Kontaktiere uns gerne, wenn Du Hilfe brauchst.</p>
          </div>

          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 1">
            <font-awesome-icon icon="spinner" class="icon" pulse />
            <p></p>
          </div>

          <div class="text-center flex flex-col gap-2" v-show="status == 0">
            <p>Wir senden Dir eine E-Mail zu, mit der Du Dein Passwort ändern kannst.</p>
            <button
              @click="sendAction()"
              class="button-filled text-center btn-fullsize w-full"
            >
              E-Mail anfordern
            </button>
          </div>

          <div class="text-center text-gray-600 font-sans">
            <p><small>ID: {{user.id}}</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalSettingAccountPasswordChange",
  data() {
    return {
      show: this.showModal,
      stepCompleteDisabled: true,
      status: 0
    };
  },
  props: {
    showModal: false,
  },
  mounted() {
    // this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    sendAction() {
      this.status = 1

      this.$store.dispatch("requestPasswordChange", this.user.email).then((a) => {
        if (a.status == true) {
          this.status = 2
        }
      });
    },
    close() {
      // this.status = 0
      this.$emit("close");
    },
  },
};
</script>




