<template>
  <div id="Account">
    <div class="card-header bg-brand px-3 py-4 rounded-t-lg">
      <p>
        <span class="brand-color-dark">Konto</span><br />
        Einstellungen zu Deinem Konto.
      </p>
    </div>
    <div class="card-content">

      <div class="grid grid-cols-2 gap-6">
                <div class="col-span-full sm:col-span-1">
                  <label for="email" class="block text-sm font-bold">E-Mail Adresse</label>
                  <!-- <input disabled :value="user.email" type="email" name="email" id="email" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm  border-gray-300 rounded-md" /> -->
                  <div class="relative mt-1">
                    <input class="w-full h-10 pl-3 pr-8 text-base rounded-lg focus:shadow-outline" type="text" disabled :value="user.email"/>
                    <button @click="showModalSettingAccountEmailChange" class="absolute inset-y-0 right-0 flex items-center px-4 font-bold text-black bg-white rounded-r-lg hover:bg-gray-100 focus:bg-gray-100">Ändern</button>
                  </div>
                </div>
                <ModalSettingAccountEmailChange
                    v-show="isModalSettingAccountEmailChangeVisible"
                    @close="closeModal"
                  />

                <div class="col-span-full sm:col-span-1">
                  <label for="password" class="block text-sm font-bold">Passwort</label>
                  <!-- <input disabled value="***************" type="password" name="password" id="password" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm  border-gray-300 rounded-md" /> -->
                  <div class="relative mt-1">
                    <input class="w-full h-10 pl-3 pr-8 text-base rounded-lg focus:shadow-outline" type="text" name="password" id="password" disabled value="***************"/>
                    <button @click="showModalSettingAccountPasswordChange" class="absolute inset-y-0 right-0 flex items-center px-4 font-bold text-black bg-white rounded-r-lg hover:bg-gray-100 focus:bg-gray-100">Ändern</button>
                  </div>
                </div>
                <ModalSettingAccountPasswordChange
                    v-show="isModalSettingAccountPasswordChangeVisible"
                    @close="closeModal"
                  />

                <hr class="col-span-full"> 

                <div class="col-span-full">
                  <label for="company" class="block text-sm font-bold">Verkehrsgesellschaft</label>
                  <input disabled :value="user.company.name" type="text" name="company" id="company" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm  border-gray-300 rounded-md" />
                </div>

                <div class="col-span-full">
                  <label for="company" class="block text-sm font-bold">Personalnummer</label>
                  <input disabled :value="user.servicecard_id" type="text" name="company" id="company" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm  border-gray-300 rounded-md" />
                  
                </div>

                <p class="input-helper-text col-span-full">
                  Wenn Du den Arbeitgeber gewechselt hast, kontaktiere uns bitte.
                </p>

                <hr class="col-span-full">


                <div class="col-span-full">
                  <label for="accounttyp" class="block text-sm font-bold">Kontoart</label>
                  <input disabled value="CLUB-MEMBER - Stadtbahnfahrer:in" type="text" name="accounttyp" id="accounttyp" class="mt-1 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm  border-gray-300 rounded-md" />
                </div>
      </div>

      <div class="mt-12">
       <button @click="showModalSettingAccountDeleteAccount"  class="card-button">Konto löschen</button>
       <ModalSettingAccountDeleteAccount
          v-show="isModalSettingAccountDeleteAccountVisible"
          @close="closeModal"
        />
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import ModalSettingAccountEmailChange from "@/components/ModalSettingAccountEmailChange";
import ModalSettingAccountPasswordChange from "@/components/ModalSettingAccountPasswordChange";
import ModalSettingAccountDeleteAccount from "@/components/ModalSettingAccountDeleteAccount";

export default {
  name: "Account",
  components: {
    ModalSettingAccountEmailChange,
    ModalSettingAccountPasswordChange,
    ModalSettingAccountDeleteAccount
  },
  data() {
    return {
      isModalSettingAccountEmailChangeVisible: false,
      isModalSettingAccountPasswordChangeVisible: false,
      isModalSettingAccountDeleteAccountVisible: false,
    };
  },
  methods: {
    showModalSettingAccountEmailChange() {
      this.isModalSettingAccountEmailChangeVisible = true;
      document.body.classList.add("modal-open");
    },
    showModalSettingAccountPasswordChange() {
      this.isModalSettingAccountPasswordChangeVisible = true;
      document.body.classList.add("modal-open");
    },
    showModalSettingAccountDeleteAccount() {
      this.isModalSettingAccountDeleteAccountVisible = true;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.isModalSettingAccountEmailChangeVisible = false;
      this.isModalSettingAccountPasswordChangeVisible = false;
      this.isModalSettingAccountDeleteAccountVisible = false;
      document.body.classList.remove("modal-open");
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>