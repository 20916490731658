<template>
  <div id="modalRegistrationRecommendCompany" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>E-Mail Adresse ändern</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <p class="text-center">Deine E-Mail Adresse ist mit Deinem Konto beim StadtbahnfahrerClub fest verknüpft. Sende uns bitte eine E-Mail für eine Änderung.</p>

          <p>Nenne uns bitte:</p>
          <ul class="list-disc list-inside">
            <li>Deine aktuelle E-Mail Adresse <i>{{user.email}}</i></li>
            <li>Deine neue E-Mail Adresse</li>
            <li>sowie Deine Nutzer-ID <i>{{user.id}}</i></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalSettingAccountEmailChange",
  data() {
    return {
      show: this.showModal,
      stepCompleteDisabled: true
    };
  },
  props: {
    showModal: false
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
};
</script>




