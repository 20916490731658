<template>
  <div id="modalRegistrationRecommendCompany" class="modal">
    <div class="modal-background"></div>
    <!-- Modal content -->
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Konto löschen</h2>
          <span class="close" @click="close">&times;</span>
        </div>
        <div class="modal-body">
          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 2">
            <font-awesome-icon icon="check" class="icon color-success" />
            <p>E-Mail versendet!<br>Wir haben Dir eine E-Mail zugesandt, mit der Du Dein Passwort ändern kannst. Prüfe bitte auch Deinen Spam-Ordner, falls Du sie nicht findest. Kontaktiere uns gerne, wenn Du Hilfe brauchst.</p>
          </div>

          <div class="text-center flex flex-col gap-2 items-center" v-show="status == 1">
            <font-awesome-icon icon="spinner" class="icon" pulse />
            <p></p>
          </div>

          <div class="text-center flex flex-col gap-2" v-show="status == 0">
            <div
        class="
          alert alert-default
          text-center
          my-3
          p-3
          rounded-lg
          text-black
          bg-gray-200
        "
      >
        <p>Wenn Du ein Problem oder eine Frage zum StadtbahnfahrerClub hast, kannst Du uns gerne <a class="link" href="https://hilfe.stadtbahnfahrer.club" target="_newTab">kontaktieren</a>.</p>
      </div>
            

            <p>Es tut uns sehr weh, wenn Du Dein Konto beim StadtbahnfahrerClub löschen möchtest, aber unsere Trennung muss auch nicht für immer sein.</p>
            <p>Beachte bitte, dass die Kontolöschung <b>unwiderruflich</b> ist. Du kannst uns gerne jederzeit erneut beitreten, Du musst Dich jedoch erneut für die Beta-Warteliste eintragen und die Verifizierung durchführen.</p>
            <p>Sende uns eine E-Mail für die Kontolöschung an:<br><i><a class="link" href="mailto:hallo@stadtbahnfahrer.club" target="_newTab">hallo@stadtbahnfahrer.club</a></i></p>
            <!-- <button
              @click="sendAction()"
              class="button-filled text-center btn-fullsize w-full"
            >
              Konto unwiderruflich löschen
            </button> -->
          </div>

          <div class="text-center text-gray-600 font-sans">
            <p><small>ID: {{user.id}}</small></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalSettingAccountPasswordChange",
  data() {
    return {
      show: this.showModal,
      stepCompleteDisabled: true,
      status: 0
    };
  },
  props: {
    showModal: false,
  },
  mounted() {
    // this.$store.dispatch("fetchUserProfile");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
  methods: {
    sendAction() {
      this.status = 1

      // this.$store.dispatch("requestPasswordReset", this.user.email).then((a) => {
      //   console.log(a);

      //   if (a.status == true) {
      //     this.status = 2
      //   }
      // });
    },
    close() {
      // this.status = 0
      this.$emit("close");
    },
  },
};
</script>




